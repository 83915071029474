import Radium from "radium";
import {fadeIn, slideInLeft, slideInUp} from "react-animations";


export const animatedItems = {
    slideInLeft: {
        animation: 'x 0.5s',
        animationName: Radium.keyframes(slideInLeft, 'slideInLeft')
    },
    fadeIn: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeIn, 'fadeIn')
    },
    slideInUp: {
        animation: 'x 0.5s',
        animationName: Radium.keyframes(slideInUp, 'slideInUp')
    },
}