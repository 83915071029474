import {useEffect, useState} from "react";
import {Button, Col, Row} from "antd";
import Intro from "./pages/home/components/Intro";
import LoginForm from "./pages/home/components/LoginForm";
import {StyleRoot} from "radium";
import {animatedItems} from "./components/Animated/AnimatedItems";
import {StaticText} from "./Static/StaticText";
import MethodGridItem from "./pages/home/components/MethodGridItem";
import smsIcon from '../src/assets/images/icon/new/sms.png';
import otpIcon from '../src/assets/images/icon/new/cellular.png';
import ussdIcon from '../src/assets/images/icon/new/passcode.png';
import AnimatedBackground from "./components/Animated/AnimatedBackground";
import USSDResponse from "./components/Pages/USSDResponse";
import SmartResponse from "./components/Pages/SmartResponse";
import SMSResponse from "./components/Pages/SMSResponse";
import SuccessLoginToDashboard from "./components/Pages/SuccessLoginToDashboard";
import UserRequest from "./utils/UserRequest";
import BaseURL from "./utils/BaseUrl";
import {Services} from "./Static/Services";
import {BeatLoader} from "react-spinners";
import Dashboard from "./pages/dashboard/Dashboard";
import {AiOutlinePoweroff} from "react-icons/ai";
import Snackbar from "./components/Notification/Snackbar";


function App() {

    const [step, setStep] = useState(1);
    const [pageStep, setPageStep] = useState(1);
    const [loginRequest, setLoginRequest] = useState(false);
    const [loginResult, setLoginResult] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectLoading, setSelectLoading] = useState(false);

    const baseUrl = BaseURL();
    let userToken = localStorage.getItem('token');

    useEffect(() => {
    }, [step]);

    const submitLogin = (res) => {
        setLoginResult(res);
        setLoginRequest(true);
        setStep(res?2: 1);
    }

    const handleSelectItem = (value) => {
        setSelectLoading(true);
        if (value === 'smart'){
            callReadyAutoLogin(value)
        }
        else if (value === 'sms'){
            callSMSLogin(value)
        }
        else{
            setSelectedItem(value);
            setStep(3);
            setSelectLoading(false)
        }
    }

    const callReadyAutoLogin = (type) => {
        UserRequest.get(`${baseUrl}${Services.readyAutoLogin}`)
            .then(res => {
                if (res.status === 200){
                    setSelectedItem(type);
                    setStep(3);
                }
                else{
                    Snackbar(StaticText.errorType, StaticText.error.apiError);
                }
                setSelectLoading(false)
            })
            .catch(() => {
                Snackbar(StaticText.errorType, StaticText.error.apiError);
                setSelectLoading(false)
            })
    }
    const callSMSLogin = (type) => {
        UserRequest.get(`${baseUrl}${Services.sendSms}`)
            .then(res => {
                if (res.status === 200){
                    setSelectedItem(type);
                    setStep(3);
                }
                else{
                    Snackbar(StaticText.errorType, StaticText.error.apiError);
                }
                setSelectLoading(false)
            })
            .catch(() => {
                Snackbar(StaticText.errorType, StaticText.error.apiError);
                setSelectLoading(false)
            })
    }
    const backToMenu = () => {
        setSelectedItem(null);
        setStep(2);
    }

    const successAuthentication = () => {
        setStep(4);
        Snackbar(StaticText.successType, StaticText.message.signingIn);
        setTimeout(() => {
            setPageStep(2)
        }, 3000);
    }



    return (
        pageStep === 1?
            <div className="wrapper min-h-screen">
                <Row gutter={[3, 4]} className='w-full min-h-screen flex-row'>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14}
                         className='mb-20 w-full md:min-h-screen md:w-full flex justify-center
                    items-center md:mb-0'>
                        <Intro/>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}
                         className='w-full h-auto md:min-h-screen md:w-full flex justify-center
                    items-center'>
                        <div className="w-[350px] flex flex-col justify-center items-center
                     backdrop-blur-[2px] p-[10px] rounded-[25px] z-50 bg-[#04040485]
                     min-h-[500px] min-w-[350px] md:p-[60px]">
                            {!userToken && step < 3 && (
                                <LoginForm
                                    style={animatedItems.slideInUp}
                                    disabled={loginResult}
                                    onSubmit={(res) => submitLogin(res)}
                                />
                            )}
                            {loginRequest && step === 1 && (
                                <h2 className="text-red-600 text-[15px]">
                                    {StaticText.validation.wrongLoginMessage}
                                </h2>
                            )}
                            {userToken && step < 3 && (
                                <StyleRoot>
                                    <div style={animatedItems.slideInUp}>
                                        <ul style={animatedItems.slideInUp} className="w-full relative">
                                            {
                                                selectLoading &&
                                                <div className='absolute w-full h-full
                                             backdrop-blur-[4px] bg-[#00000000] left-0 rounded-[25px]
                                             text-gray-100 flex justify-center items-center'>
                                                    <BeatLoader size={8} color='#fff' />
                                                </div>
                                            }
                                            <MethodGridItem
                                                title={StaticText.label.otp}
                                                icon={otpIcon}
                                                onSelect={() => handleSelectItem('smart')}
                                            />
                                            <MethodGridItem
                                                title={StaticText.label.ussd}
                                                icon={ussdIcon}
                                                onSelect={() => handleSelectItem('ussd')}
                                            />
                                            <MethodGridItem
                                                title={StaticText.label.sms}
                                                icon={smsIcon}
                                                onSelect={() => handleSelectItem('sms')}
                                            />
                                        </ul>
                                    </div>
                                    <Button type="primary" shape="circle" size='large'
                                        className='text-xl absolute top-7
                                        right-7 cursor-pointer bg-transparent rounded-full
                                        p-6 flex justify-center items-center hover:!bg-transparent
                                        border-1 border-red-500 border-solid'
                                    onClick={() => {
                                        localStorage.clear();
                                        window.location.reload()
                                    }}
                                    >
                                        <AiOutlinePoweroff
                                            className='!text-red-500 hover:!text-red-600'
                                        />
                                    </Button>

                                </StyleRoot>
                            )}
                            {userToken && step === 3 && selectedItem === 'smart' &&
                                <SmartResponse
                                    expireCounter={backToMenu}
                                    onSuccess={() => successAuthentication()}
                                />
                            }
                            {userToken && step === 3 && selectedItem === 'ussd' &&
                                <USSDResponse
                                    expireCounter={backToMenu}
                                    onSuccess={() => successAuthentication()}/>
                            }
                            {userToken && step === 3 && selectedItem === 'sms' &&
                                <SMSResponse
                                    expireCounter={backToMenu}
                                    onSuccess={() => successAuthentication()}
                                />
                            }
                            {
                                step === 4 &&
                                <SuccessLoginToDashboard />
                            }
                        </div>
                    </Col>
                </Row>
                <AnimatedBackground />
            </div>:
            <Dashboard />
    );
}

export default App;
