
import React from 'react';
import {FiCheckCircle} from "react-icons/fi";

const SuccessLoginToDashboard = () => {

    return(
        <div className='flex justify-center items-center flex-col'>
            <FiCheckCircle className='text-green-600 text-8xl' />
            <h2 className='text-gray-200 text-[18px] mt-8 text-center'>
                احراز هویت با موفقیت انجام شد
            </h2>
        </div>
    )
}

export default SuccessLoginToDashboard;