import {Image} from "antd";
import Logo from '../../../assets/images/setareaval.svg';
import {TypeAnimation} from "react-type-animation";

const Intro = () => {
    return (
        <div className='flex flex-col justify-center items-center z-50 mt-20
            md:mt-0 px-4'>
            <Image src={Logo} preview={false} loading={"lazy"} width={250} />
            <TypeAnimation
                sequence={[
                    'سامانه احراز هویت هوشمند از طریق پیام کوتاه',
                    2000,
                    'سامانه احراز هویت هوشمند از طریق کد دستوری',
                    2000,
                    'سامانه احراز هویت هوشمند از طریق OTP',
                    2000
                ]}
                wrapper="span"
                speed={200}
                className='typingStyle'
                repeat={Infinity}
            />
        </div>
    )
}

export default Intro;