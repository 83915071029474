import React, {useEffect, useRef, useState} from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {StaticSetting} from "../../utils/StaticSetting";

const CustomRenderTime = ({ remainingTime }) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
        isNewTimeFirstTick.current = true;
        prevTime.current = currentTime.current;
        currentTime.current = remainingTime;
    } else {
        isNewTimeFirstTick.current = false;
    }

    if (remainingTime === 0) {
        setTimeout(() => {
            setOneLastRerender((val) => val + 1);
        }, 20);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
        <div className="time-wrapper">
            <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
                {remainingTime}
            </div>
            {prevTime.current !== null && (
                <div
                    key={prevTime.current}
                    className={`time ${!isTimeUp ? "down" : ""}`}>
                    {prevTime.current}
                </div>
            )}
        </div>
    );
};

const CircularCounter = ({onExpireCounter}) => {

    const [completeTime, setCompleteTimer] = useState(false);
    useEffect(() => {

    }, [completeTime]);

    return(
        <div className="timer-wrapper">
            {
                completeTime?
                    <>
                        <button onClick={() => setCompleteTimer(false)}>
                            شروع مجدد
                        </button>
                    </>:
                    <CountdownCircleTimer
                        className='circularCounter'
                        isPlaying
                        size={130}
                        strokeWidth={7}
                        duration={StaticSetting.countDownTimer}
                        colors={['#007735', '#f7c601', '#A30000', '#A30000']}
                        onComplete={onExpireCounter}
                        style={{
                            color: '#fff'
                        }}
                        colorsTime={[120, 60, 20, 0]}>
                        {CustomRenderTime}
                    </CountdownCircleTimer>
            }
        </div>
    )
}

export default CircularCounter;
