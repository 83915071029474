import React, {useState} from 'react';
import {Button, Form, Input} from 'antd';
import {StaticText} from "../../../Static/StaticText";
import axios from "axios";
import BaseURL from "../../../utils/BaseUrl";
import {Services} from "../../../Static/Services";
import {BeatLoader} from "react-spinners";
import Snackbar from "../../../components/Notification/Snackbar";


const LoginForm = ({onSubmit, disabled}) => {

    const [formValue, setFormValue] = useState({
        username: '',
        password: ''
    });
    const [loginLoading, setLoginLoading] = useState(false);

    const baseUrl = BaseURL();
    const [form] = Form.useForm();

    const handleChange = (e, name) => {
        e.preventDefault();
        setFormValue({
            ...formValue,
            [name]: e.target.value
        })
    }

    const handleLogin = (e) => {
        e.preventDefault();
        form.validateFields()
            .then(() => loginApi())
            .catch((err) => console.log(err))
    }

    const loginApi = () => {
        setLoginLoading(true);
        axios({
            method: 'post',
            url: `${baseUrl}${Services.login}`,
            data: {
                "username": formValue.username,
                "password": formValue.password
            }
        })
            .then(res => {
                if (res.status === 200){
                    localStorage.setItem(StaticText.token, res.data.jwtToken);
                    onSubmit(true);
                }
                setLoginLoading(false);
            })
            .catch(() => {
                setLoginLoading(false);
                Snackbar(StaticText.errorType,StaticText.validation.wrongLoginMessage);
                onSubmit(false);
            })
    }

    return(
        <div className='relative z-50'>
            {
                disabled &&
                <div className='fixed w-full h-[220px] md:h-[270px] left-0 top-0 backdrop-blur-[2px]
                    z-30 bg-[#2c272761] rounded-2xl' />
            }
            <Form
                form={form}
                name="loginForm"
                className='loginFormStyle z-20' >
                <Form.Item
                    name={StaticText.username}
                    rules={[
                        {
                            required: true,
                            message: StaticText.validation.emptyUsernameError,
                        },
                    ]}>
                    <Input
                        type='text'
                        placeholder={StaticText.label.username}
                        size='large'
                        onChange={(e) => handleChange(e, StaticText.username)}
                    />
                </Form.Item>

                <Form.Item
                    name={StaticText.password}
                    rules={[
                        {
                            required: true,
                            message: StaticText.validation.emptyPasswordError,
                        },
                    ]}
                >
                    <Input.Password
                        placeholder={StaticText.label.password}
                        size='large'
                        onChange={(e) => handleChange(e, StaticText.password)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType="submit"
                        disabled={loginLoading}
                        onClick={handleLogin}
                        className={`submitButton w-full h-12 bg-green-500 hover:bg-green-600
                            text-gray-200 disabled:bg-green-600
                            hover:!text-gray-100 !outline-none !border-0 
                            text-[16px]`}>
                        {
                            loginLoading?
                                <BeatLoader color='#fff' size={8} />:
                                StaticText.label.login
                        }
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default LoginForm;