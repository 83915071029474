import React, {useState} from 'react';
import CircularCounter from "../Counter/CircularCounter";
import {Form, Input} from "antd";
import SubmitButton from "../SubmitButton";
import UserRequest from "../../utils/UserRequest";
import {Services} from "../../Static/Services";
import {StaticText} from "../../Static/StaticText";
import BaseURL from "../../utils/BaseUrl";
import BackButton from "../BackButton";
import Snackbar from "../Notification/Snackbar";

const USSDResponse = ({expireCounter, onSuccess}) => {

    const [inputValue, setInputValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const baseUrl = BaseURL();

    const onFinish = () => {
        setLoading(true);
        UserRequest.get(`${baseUrl}${Services.ussdVerify}${inputValue}`)
            .then(res => {
                if (res.status === 200 && res.data){
                    onSuccess();
                }
                else{
                    Snackbar(StaticText.errorType, StaticText.error.wrongSmsPinCode)
                }
                setLoading(false)
            })
            .catch(() => {
                setLoading(false);
                Snackbar(StaticText.errorType, StaticText.error.apiError)
            })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const handleChange = (e) => {
        e.preventDefault();
        setInputValue(e.target.value)
    }

    return(
        <div className='max-w-full flex flex-col justify-center items-center'>
            <BackButton onClick={expireCounter} />
            <p className='text-gray-300 text-center text-[16px] mt-8 mb-10 leading-8'>
                  لطفا با تلفن همراه ثبت شده در سامانه، #124*1* را شماره گیری نمایید و کد مشاهده شده را وارد نمایید
            </p>
            <div className='counterFrame mb-10'>
                <CircularCounter onExpireCounter={expireCounter}  />
            </div>
            <Form
                className='customFrom'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off">
                <Form.Item
                    name="code"
                    size='large'
                    rules={[
                        {
                            required: true,
                            message: 'لطفا عدد ارسال شده را وارد کتید',
                        },
                        {
                            max: 6,
                            message: 'تعداد کارکتر وارد شده بیش از حد مجاز است',
                        }
                    ]}>
                    <Input
                        size='large'
                        onChange={(e) => handleChange(e)}
                        placeholder='- - - - -' />
                </Form.Item>
                <Form.Item>
                    <SubmitButton
                        loading={loading}
                        title='ارسال'
                        width={150}
                    />
                </Form.Item>
            </Form>
        </div>
    )
}

export default USSDResponse;