import React from 'react';
import {AiOutlineArrowLeft} from "react-icons/ai";

const BackButton = ({onClick}) => {
    return(
        <div onClick={onClick}
             className='fixed top-6 left-6 text-gray-300 flex flex-row items-center
                    cursor-pointer'>
            <AiOutlineArrowLeft className='mr-1 text-md' />
            بازگشت
        </div>
    )
}

export default BackButton;