import axios from 'axios';
import BaseURL from "./BaseUrl";


let baseURL = BaseURL();
const UserRequest = axios.create({
    timeout: 300000,
});

UserRequest.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('token');
        config.baseURL = baseURL;
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    function (err) {
        return Promise.reject(err);
    },
);

UserRequest.interceptors.response.use(
    function (response) {
        return response;
    },
    function (err) {
        return Promise.reject(err.response);
    },
);

export default UserRequest;
