import React from 'react';
import { MailOutlined, BellOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import Logo from '../../assets/images/setareaval.svg';
const { Header, Content, Footer, Sider } = Layout;
const items1 = [BellOutlined, MailOutlined ].map((icon, index) => {
    const key = String(index + 1);
    return {
        key: `${key}`,
        icon: React.createElement(icon),
    };
});
const items2 = ['گزارشگیری','لیست درخواست ها','خروج'].map((label, index) => {
    const key = String(index + 1);
    return {
        key: `sub${key}`,
        label: `${label}`,
        onClick: () => {
            localStorage.clear();
            window.location.reload();
        }
    };
});
const Dashboard = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    return (
        <Layout dir='rtl'>
            <Header className='dashboardHeader flex p-0 flex-row justify-between'>
                <img src={Logo} alt='ستاره اول' width={100} />
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['2']}
                    items={items1}
                    style={{
                        flex: 1,
                        minWidth: 0,
                        justifyContent: 'flex-end'
                    }}
                />
            </Header>
            <Content>
                <Layout
                    style={{
                        padding: '24px 0',
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Sider
                        style={{
                            background: colorBgContainer,
                        }}
                        width={200}
                    >
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{
                                height: '100%',
                            }}
                            items={items2}
                        />
                    </Sider>
                    <Content className='min-h-[calc(100vh-200px)]'>
                        <h2 className='text-xl mr-4'>
                            به پنل کاربری ستاره اول خوش آمدید
                        </h2>
                    </Content>
                </Layout>
            </Content>
            <Footer className='text-center'>
                طراحی و توسعه | ستاره اول 1402
            </Footer>
        </Layout>
    );
};
export default Dashboard;