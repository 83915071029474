import React, {useEffect} from 'react';
import CircularCounter from "../Counter/CircularCounter";
import UserRequest from "../../utils/UserRequest";
import {Services} from "../../Static/Services";
import {StaticText} from "../../Static/StaticText";
import BaseURL from "../../utils/BaseUrl";
import BackButton from "../BackButton";
import Snackbar from "../Notification/Snackbar";

const SmartResponse = ({expireCounter, onSuccess}) => {

    const baseUrl = BaseURL();
    useEffect(() => {
        const autoLoginInterVal = setInterval(() => {
            verifyAutoLogin();
        }, 2000);
        return () => {
            clearInterval(autoLoginInterVal);
        };
    }, []);

    const verifyAutoLogin = () => {
        UserRequest.get(`${baseUrl}${Services.verifyAutoLogin}`)
            .then(res => {
                if (res.status === 200 && res.data){
                    onSuccess();
                }
            })
            .catch(() => Snackbar(StaticText.errorType,StaticText.error.apiError))
    }

    return(
        <div className='max-w-full flex flex-col justify-center items-center'>
            <BackButton onClick={expireCounter} />
            <p className='text-gray-300 text-center text-[16px] mt-8 mb-10 leading-8'>
                 لطفا با تلفن همراه ثبت شده در سامانه، #124*1* را شماره گیری کرده و گزینه ورود هوشمند را انتخاب نمایید
            </p>
            <div className='counterFrame mb-10'>
                <CircularCounter onExpireCounter={expireCounter} />
            </div>

        </div>
    )
}

export default SmartResponse;