import React from 'react';

const MethodGridItem = ({title, icon, onSelect}) => {
    return(
        <li
            className='w-full shadow-[rgb(0 0 0 / 59%) 0px 0px 16px] flex flex-row
                justify-center items-center
                bg-black px-6 py-4 cursor-pointer rounded-lg mb-3 text-[16px]
                font-medium text-gray-200 bg-black'
            onClick={onSelect}>
            <img src={icon} alt={title} className='w-[40px] mr-4' />
            {title}
        </li>
    )
}

export default MethodGridItem;