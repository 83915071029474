import React from 'react'
import {BeatLoader} from "react-spinners";

const SubmitButton = ({title, loading}) => {
    return(
        <button
            disabled={loading}
            type='submit'
            className={`w-full bg-green-500 hover:bg-green-600 hover:text-gray-100 text-gray-100
                h-14 rounded-md transition duration-400  text-[16px]`}>
            {
                loading?
                    <BeatLoader size={9} color='#fff' />:title
            }
        </button>
    )
}

export default SubmitButton;