import {notification} from "antd";

const Snackbar = (type, title) => {
    return(
        notification.open({
            type: type,
            message: title,
            placement: 'bottomRight'
        })
    )
}

export default Snackbar;