
export const StaticText = {
    username: 'username',
    password: 'password',
    token: 'token',
    successType: 'success',
    errorType: 'error',
    label: {
        username: 'نام کاربری',
        password: 'کلمه عبور',
        login: 'ورود',
        ussd: 'کد دستوری',
        sms: 'پیام کوتاه',
        otp: 'ورود هوشمند'
    },
    validation: {
        emptyUsernameError: 'نام کاربری را وارد کنید',
        emptyPasswordError: 'کلمه عبور را وارد کنید',
        wrongLoginMessage: 'نام کاربری یا کلمه عبور اشتباه است',
    },
    error: {
        apiError: 'خطایی پیش آمده. دوباره تلاش کنید',
        wrongSmsPinCode: 'کد وارد شده اشتباه است',
    },
    message: {
        signingIn: 'در حال ورود به پنل کاربری',

    }
}